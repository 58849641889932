import { Injectable } from '@angular/core';
import * as uuid from 'uuid';
import { Region, RequestStatus } from '../model/request';
import { mockQuestionBank, mockSkeletonBank } from '../mock-data/mock-questionnaire-data';
import { DDQStatus } from '../model/ddq';
import { Request } from '../model/request';
import { mockDDQConfig } from '../mock-data/mock-ddq-config';
import { ApiService } from '../../services/api.service';
import { take } from 'rxjs/internal/operators/take';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { KydRequestService } from './kyd-request.service';
import { KydUserService } from './kyd-user.service';
import * as FileSaver from 'file-saver';
import { DistributorsService } from './distributors.service';
import { KYDRequestData } from '../model/kyd-request';
import { CurrentUserService } from '../../services/current-user.service';
import { AnswersService } from './answers.service';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService {
  requestKey: string;
  questionnaireKey: string;
  ddqStatus: DDQStatus;
  requestStatus: RequestStatus;
  progressPercent;
  disableSaving = true;
  isNotified = false;

  allKeys;
  foundField;

  saveSubject: Subject<boolean> = new Subject<boolean>();
  signedOffSubject: Subject<boolean> = new Subject<boolean>();

  clonedDDQ;

  constructor(
    private apiService: ApiService,
    private kydRequestService: KydRequestService,
    private distributorsService: DistributorsService,
    private kydUserService: KydUserService,
    private currentUserService: CurrentUserService,
    private answerService: AnswersService
  ) {}

  getQuestionnaireNew(region: string, version: string, subscriber) {
    this.apiService
      .get<any>(`ddq/question-template/${region}/${version}`)
      .pipe(
        take(1),
        map((questionnaire) => questionnaire)
      )
      .subscribe(subscriber);
  }

  // old depreciated:
  getQuestionnaire(formID: string, formVersion: string, subscriber) {
    if (formVersion) {
      this.apiService
        .get<any>(`ddq/getversion/${formID}/${formVersion}`)
        .pipe(
          take(1),
          map((questionnaire) => questionnaire.body)
        )
        .subscribe(subscriber);
    } else {
      this.apiService
        .get<any>(`ddq/get/${formID}`)
        .pipe(
          take(1),
          map((questionnaire) => questionnaire.body)
        )
        .subscribe(subscriber);
    }
  }

  generateDDQConfig(region: Region, distributorID: string, subscriber): any {
    // TODO update compile DDQ config code to use new DDQ config models
    // const questionnaire = this.compileDDQConfig(region)

    // old form creation
    const questionnaire = {
      sections: mockDDQConfig.sections,
      version: mockDDQConfig.version,
    };

    // new answer pool creation
    const answers = {
      lastVersionChangeByID: '',
      mostRecentRelease: '',
    };

    // TODO create questionnaire model

    // old method, depreciated
    // this.apiService.post<any, any>(`ddq/create`, questionnaire).subscribe(subscriber);

    // new method
    this.apiService.post<any, any>(`ddq/create-new`, answers).subscribe(subscriber);
  }

  compileDDQConfig(region: Region) {
    // Grab skeleton (also mocked)
    const skeleton = mockSkeletonBank.find((currSkeleton) => currSkeleton.region === region);

    const ddqConfigSections = {};

    // Grab question bank questions pointed to by template (get mocked data for now)
    skeleton?.sections.map((section) => {
      ddqConfigSections[section.text] = [];
      section.questions.map((question) => {
        // fetch question from dynamodb
        // TODO replace this with actual DB call
        const questionObject = mockQuestionBank.find((questionObj) => questionObj.created === question);
        ddqConfigSections[section.text].push(questionObject);
      });
    });

    const questionnaire = {
      status: DDQStatus.pending,
      sections: [],
    };

    for (const sectionName in ddqConfigSections) {
      if (ddqConfigSections.hasOwnProperty(sectionName)) {
        const formattedSection = {
          id: uuid.v4(),
          name: sectionName,
        };

        const formattedQuestions = ddqConfigSections[sectionName].map((question) => {
          const formattedQuestion = {
            id: question.created,
            text: question.text,
          };

          // TODO implement more complex formatting of fields (given final config model)
          const formattedFields = question.fields.map((field) => {
            field.id = uuid.v4();
            return field;
          });

          // @ts-ignore
          formattedQuestion.fields = formattedFields;

          return formattedQuestion;
        });

        const formattedPages = [];

        for (let i = 0; i <= formattedQuestions.length; i += 5) {
          formattedPages.push({
            id: uuid.v4(),
            questions: formattedQuestions.slice(i, i + 5),
          });
        }

        // @ts-ignore
        formattedSection.pages = formattedPages;

        questionnaire.sections.push(formattedSection);
      }
    }

    return questionnaire;

    // Get issuer-specific questions

    // combine all above
    // Generate questionnaire and attach to distributor
  }

  // @debounce()
  // updateQuestionnaire(questionnaire: any) {
  //   this.currentUserService.currentUser.subscribe((user) => {
  //     questionnaire.lastVersionChangeByID = user.id;
  //   })
  //   this.apiService.post(`ddq/update/${this.questionnaireKey}`, questionnaire).subscribe(() => {
  //     this.updateLiveProgress();
  //     this.saveSubject.next(true);
  //   });
  // }

  @debounce()
  async updateQuestionnaire(answers: any) {
    const id = this.questionnaireKey;
    this.currentUserService.currentUser.subscribe((user) => {
      answers.lastVersionChangeByID = user.id;
      this.apiService.post<any, any>(`ddq/answer-pool/dump/${id}`, answers).subscribe(() => {
        this.updateLiveProgress();
        this.saveSubject.next(true);
      });
    });
  }

  updateLiveProgress() {
    this.kydRequestService.getRequest(this.requestKey, (request: KYDRequestData) => {
      request.details.form.liveProgress = this.progressPercent;
      this.kydRequestService.updateDDQDetails(request, request.details).subscribe();
    });
  }

  publishDraft(questionnaire: any, request: Request, newFormStatus: DDQStatus, newRequestStatus: RequestStatus = null) {
    questionnaire.mostRecentRelease = new Date().toISOString();
    this.apiService.post(`ddq/update-answers/${this.questionnaireKey}`, questionnaire).subscribe(async (s3Obj: any) => {
      request.details.form.status = newFormStatus;
      request.details.form.draftVersion = s3Obj.version;
      if (newFormStatus == DDQStatus.signed) {
        request.details.distributor.signedOffTimeStamp = questionnaire.mostRecentRelease;
      }

      request.details.form.progress = this.progressPercent ?? request.details.form.progress ?? '0';

      await this.kydRequestService.updateDDQDetails(request, request.details).toPromise();

      if (newRequestStatus) {
        this.kydRequestService.updateRequestStatus(request, newRequestStatus);
      } else {
        this.kydRequestService.refreshSubject.next(true);
      }
    });
  }

  async publishDraftAsync(
    questionnaire: any,
    request: Request,
    newFormStatus: DDQStatus,
    newRequestStatus: RequestStatus = null
  ) {
    questionnaire.mostRecentRelease = new Date().toISOString();
    return await this.apiService
      .post(`ddq/update-answers/${this.questionnaireKey}`, questionnaire)
      .toPromise()
      .then(async (s3Obj: any) => {
        request.details.form.status = newFormStatus;
        request.details.form.draftVersion = s3Obj.version;
        if (newFormStatus == DDQStatus.signed) {
          request.details.distributor.signedOffTimeStamp = questionnaire.mostRecentRelease;
        }

        request.details.form.progress = this.progressPercent ?? request.details.form.progress ?? '0';

        await this.kydRequestService.updateDDQDetails(request, request.details).toPromise();

        if (newRequestStatus) {
          await this.kydRequestService.updateRequestStatusAsync(request, newRequestStatus);
        } else {
          this.kydRequestService.refreshSubject.next(true);
        }
      });
  }

  // saveDDQ(newFieldValue, field, question, config, control) {
  //   field.value = newFieldValue;
  //   field.disabled = control.disabled;

  //   this.updateQuestionnaire(config);
  // }

  saveDDQ(answers) {
    this.updateQuestionnaire(answers);
  }

  // old T and C string method, deprecated...
  getTermsAndConditionsString(questionnaire): string {
    const termsAndConditionsSection = questionnaire.sections.find(
      (section) => section.id === '2eba960e-cc13-4c19-85f9-c5d574b39ce7'
    ).subsections[0];

    const allQuestions = termsAndConditionsSection.questions;
    const tsAndCsQuestions = [];

    for (const question of allQuestions) {
      if (question.fields.find((field) => field.type === 'checkbox')) {
        tsAndCsQuestions.push(question);
      }
    }

    const sorted = tsAndCsQuestions.sort((qa, qb) => {
      const qaLastNavNum = qa.nav.split('.')[qa.nav.split('.').length - 1];
      const qbLastNavNum = qb.nav.split('.')[qb.nav.split('.').length - 1];
      return parseInt(qaLastNavNum) - parseInt(qbLastNavNum);
    });

    let termsAndConditionsString = ``;

    for (const question of sorted) {
      const checkboxField = question.fields.find((field) => field.type === 'checkbox');
      if (checkboxField.value) {
        termsAndConditionsString += `${question.text}\n\n${checkboxField.label}\n`;

        if (question.fields.length > 1) {
          termsAndConditionsString += '\n';
          let extraText = question.fields[1].text;
          extraText = extraText.replaceAll('<b>', '');
          extraText = extraText.replaceAll('</b>', '');
          extraText = extraText.replaceAll('<li>', '*  ');
          extraText = extraText.replaceAll('</li>', '\n');
          extraText = extraText.replaceAll('<ul>', '');
          extraText = extraText.replaceAll('</ul>', '');
          termsAndConditionsString += `${extraText}\n`;
        }

        termsAndConditionsString += `\n`;
      }
    }

    return termsAndConditionsString;
  }

  async getTandCStringNew(questionnaire, formId) {
    const termsAndConditionsSection = questionnaire.sections.find(
      (section) =>
        section.id === '2eba960e-cc13-4c19-85f9-c5d574b39ce7' ||
        section.id === '8a9f3f09-9d91-4f9a-9635-9035f100cb24' ||
        section.id === '424a9a09-c781-4152-8546-6059aeb774dc'
    ).subsections[0];
    const allQuestions = termsAndConditionsSection.questions;
    const tsAndCsQuestions = [];

    for (const question of allQuestions) {
      if (question.fields.find((field) => field.type === 'checkbox')) {
        tsAndCsQuestions.push(question);
      }
    }

    const sorted = tsAndCsQuestions.sort((qa, qb) => {
      const qaLastNavNum = qa.nav.split('.')[qa.nav.split('.').length - 1];
      const qbLastNavNum = qb.nav.split('.')[qb.nav.split('.').length - 1];
      return parseInt(qaLastNavNum) - parseInt(qbLastNavNum);
    });

    let termsAndConditionsString = ``;

    for (const question of sorted) {
      const checkboxField = question.fields.find((field) => field.type === 'checkbox');
      // console.log(checkboxField, 'this field!!!')
      if (await this.answerService.getAnswerByQuestionId(formId, checkboxField.id)) {
        termsAndConditionsString += `${question.text}\n\n${checkboxField.label}\n`;

        if (question.fields.length > 1) {
          termsAndConditionsString += '\n';
          let extraText = question.fields[1].text;
          extraText = extraText.replaceAll('<b>', '');
          extraText = extraText.replaceAll('</b>', '');
          extraText = extraText.replaceAll('<li>', '*  ');
          extraText = extraText.replaceAll('</li>', '\n');
          extraText = extraText.replaceAll('<ul>', '');
          extraText = extraText.replaceAll('</ul>', '');
          termsAndConditionsString += `${extraText}\n`;
        }

        termsAndConditionsString += `\n`;
      }
    }

    return termsAndConditionsString;
  }

  async notifyDistributor() {
    await this.kydRequestService.getRequest(this.requestKey, async (request) => {
      await this.distributorsService.getDistributor(request.details.distributor.name).subscribe(async (distributor) => {
        await this.kydUserService.sendNotifyEmail(request);
        const distributorName = distributor.name;
        localStorage.setItem('distributor-name', distributorName);
        this.isNotified = true;
      });
    });
  }

  downloadDDQ(distributorName, requestKey = null) {
    return new Promise((resolve) => {
      requestKey = requestKey ?? this.requestKey;
      this.apiService.get<any>(`ddq/requests/${requestKey}/docx`).subscribe((docx) => {
        const ddqBuffer = this.convertbase64toArrayBuffer(docx);
        var blob = new Blob([ddqBuffer], { type: 'application/msword' });
        FileSaver.saveAs(blob, `${distributorName}.docx`);
        resolve(true);
      });
    });
  }

  downloadDDQ2021(distributorName, requestKey = null) {
    return new Promise((resolve) => {
      requestKey = requestKey ?? this.requestKey;
      this.apiService.get<any>(`ddq/requests/${requestKey}/docx/2021`).subscribe((docx) => {
        const ddqBuffer = this.convertbase64toArrayBuffer(docx);
        var blob = new Blob([ddqBuffer], { type: 'application/msword' });
        FileSaver.saveAs(blob, `${distributorName}_v2021.docx`);
        resolve(true);
      });
    });
  }

  convertbase64toArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
}

export function debounce(delay: number = 500): MethodDecorator {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const timeoutKey = Symbol();

    const original = descriptor.value;

    descriptor.value = function (...args) {
      clearTimeout(this[timeoutKey]);
      this[timeoutKey] = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}
