import {Component, OnDestroy, OnInit} from '@angular/core'
import {Subscription} from 'rxjs'
import {UntypedFormControl, Validators} from '@angular/forms'
import {AuthService} from '../../services/auth.service'
import {faEyeSlash} from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import {faEye} from '@fortawesome/free-solid-svg-icons/faEye'
import { ModalService } from 'src/app/ui-kit/dc-modal/modal.service'
import { TERMSOFUSE } from 'src/app/views/terms-of-use/terms-of-use-text'
import { DATAPROTECTIONADDENDUM } from 'src/app/views/data-protection-addendum/data-protection-addendum-text'

@Component({
  selector: 'app-new-password-form',
  templateUrl: './new-password-form.component.html',
  styleUrls: ['./new-password-form.component.scss'],
})
export class NewPasswordFormComponent implements OnInit, OnDestroy {
  sub = new Subscription()

  displayed: boolean = false
  submitted: boolean = false

  faEyeSlash = faEyeSlash
  faEye = faEye

  name = new UntypedFormControl('', [Validators.required])
  password1 = new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(64)])
  password2 = new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(64)])
terms = new UntypedFormControl(null, [Validators.required])
  showPassword: boolean
  errorMessage: string

  constructor(private authService: AuthService, private modalService: ModalService) {
  }

  ngOnInit(): void {
    this.sub.add(
      this.authService.onNewPasswordRequired.subscribe(newPasswordNeeded => {
        this.displayed = newPasswordNeeded
        this.submitted = false
      }),
    )
    this.sub.add(
      this.authService.onAuthError.subscribe(err => {
        this.errorMessage = err && err.message
        this.submitted = false
      }),
    )

    this.name.reset()
    this.sub.add(
      this.name.valueChanges.subscribe(() => {
        this.errorMessage = undefined
      }),
    )

    this.password1.reset()
    this.sub.add(
      this.password1.valueChanges.subscribe(() => {
        this.errorMessage = undefined
      }),
    )

    this.password2.reset()
    this.sub.add(
      this.password2.valueChanges.subscribe(() => {
        this.errorMessage = undefined
      }),
    )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  openPolicyModal(text) {
    this.modalService
      .openDialog({
        title: text == 'terms' ? 'Terms of Use' : 'Data Protection Addendum',
        body: text == 'terms' ? TERMSOFUSE : DATAPROTECTIONADDENDUM,
        buttons: {
          positive: 'Close',
          close: true,
        },
        size: 'xl',
        scrollable: true,
        windowClass: 'xlScrollableModal printDoc',
      })
      .then(
        (result) => {},
        (error) => {
          console.log(error);
        }
      );
  }


  canSaveNewPassword() {
    console.log(this.terms)
    return (!this.submitted && this.name.valid && this.password1.valid && this.password2.valid && this.terms.value )
  }

  saveNewPassword() {
    this.submitted = true
    if (this.password1.value !== this.password2.value) {
      this.errorMessage = 'Passwords don\'t match'
    } else {
      this.authService.saveNewPassword(this.password1.value, this.name.value)
    }
  }
}
